import AuthService from '@/services/authService'
import MarketService from '@/services/marketService'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  userProfile: {
    fullname: '',
    username: ''
  },
  marketGroups: [],
  marketPayrates: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateProfile (state, profile) {
    state.userProfile = profile
  },
  updateMarketGroup(state, groups) {
    state.marketGroups = groups
  },
  updateMarketPayrate(state, rates) {
    state.marketPayrates = rates
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile().then((profile) => {
      commit('updateProfile', profile)
    })
  },
  reloadMarketGroups({ commit }) {
    MarketService.getGroups().then((response)=>{
      if(response.success) {
        const groups = response.data.filter((group)=>{
          return true //group.status === 'Active'
        })
        .map((group)=>{
          return {
            _id: group._id,
            groupTitle: group.groupTitle,
            groupType: group.groupType,
            status: group.status
          }
        })

        commit('updateMarketGroup', groups)
      }
    })
  },
  reloadMarketPayrates({ commit }) {
    MarketService.getPayRates().then((response)=>{
      if(response.success) {
        const rates = response.data.map((rate)=>{
          return {
            _id: rate._id,
            rateTitle: rate.rateTitle,
            status: rate.status
          }
        })

        commit('updateMarketPayrate', rates)
      }
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
