import axios from 'axios';
import auth from "./authService"

axios.interceptors.request.use(async function(config) {
  const token = await auth.getToken()
  config.withCredentials = true
  config.headers.Authorization = `Bearer ${token}`
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error.response.data);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error.response.status === 401)
      return auth.logout()

    return Promise.reject(error.response.data);
  }
);

export default axios
